<template>
  <div id="st-container" class="st-container d-flex select-month" :class="theme">
    <div class="" v-if="theme==='theme1'">
      <span class="input-group-text">
        <i class="far fa-calendar-alt"></i>
      </span>
    </div>
    <SearchMultiSelect
      :selectedOptions="selected"
      :sourceOptions="monthOptions"
      :isCheckbox="2"
      :placeholder="$t('search-tab.choose-date')"
      @onChangeSelect="changedMultiSelection"
      :disabled="disabled"
      :theme="theme"
    />
    <div class="input-group-append" v-if="theme==='' || theme==='theme0'">
      <span class="input-group-text">
        <img :src="`${imageDomain}/assets/img/calendar.png`" alt="calendar" style="width:25px;" width="15" height="25"/>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'SelectMonths',
  mixins: [imageUrlMixin],
  components: {
    SearchMultiSelect: () => import('@/components/atoms/SearchMultiSelect'),
  },
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    availableDates: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      searchContent: 'GET_SEARCH_CONTENT',
    }),
    selected() {
      return this.searchContent.month;
    },
    monthOptions() {
      const { availableDates } = this;
      if (!availableDates) return [];
      dayjs.locale(this.lang);

      const fltListBySameMonth = availableDates.reduce((total, item) => {
        if (total.findIndex((t) => (t.date.indexOf(item.date.substr(0, 7)) > -1)) === -1) return total.concat(item);
        return total;
      }, []);
      fltListBySameMonth.sort((a, b) => (new Date(a.date) - new Date(b.date)));
      return fltListBySameMonth.map((item, inx) => {
        const nowDate = dayjs(item.date);
        const month = nowDate.format('MMMM').toLowerCase(), year = nowDate.year();
        return {
          id: inx,
          value: nowDate.format('YYYY-MM'),
          label: `${month} ${year}`,
        };
      });
    },
  },
  watch: {
    monthOptions() {
      this.getMonthList();
    },
  },
  data() {
    return {
      query: this.$route.query,
    };
  },
  methods: {
    changedMultiSelection(pData) {
      this.$emit('changeMonths', pData);
    },
    getMonthList() {
      if (!this.monthOptions.length) return;
      const strMonth = this.query?.yearMonth || '',
        monthIdList = strMonth !== '' ? strMonth.split(',') : [];

      if (monthIdList.findIndex((id) => this.monthOptions.findIndex((month) => month.value === id) > -1) > -1) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['month', monthIdList.map((id) => this.monthOptions.find((month) => month.value === id))]);
      } else {
        this.$store.dispatch('SET_SEARCH_ITEM', ['month', []]);
      }
    },
  },
};
</script>
<style scoped>
  .st-container {
    overflow: visible;
    width: 100%;
  }
  .input-group-append {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 48px;
  }
</style>
<style>
input[type='search'] {
  margin: 0;
  padding: 0;
  border: 0;
}
.filter-tab .st-container .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: 3rem;
}
.vs__selected {
  width: max-content;
}

.style-chooser .vs__dropdown-menu li {
  height: 45px;
  line-height: 35px;
  transition: 0.2s;
}

.style-chooser .vs__selected-options {
  display: contents;
}

.filter-tab form button.btn {
  height: 45px;
}
.checkbox-label {
  display: block;
}

.checkbox {
  position: absolute;
  left: 1vw;
}
.multiselect__tags {
  border-radius: 25px;
}
.theme1 .input-group-text {
  height: 48px;
  background-color: #fff;
  border-radius: 5px;
}
.select-month.theme1 .multiselect__tags{
  border-radius: 5px 0px 0px 5px;
}
.select-month.theme1 .input-group-text{
  border-radius: 0px 5px 5px 0px;
}
</style>
